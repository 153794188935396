import { graphql } from 'gatsby'
import React from 'react'
import LiveStreamBase from '../components/live-stream-base'
import { TWITCH_ACCOUNT } from '../config'

class LiveStream extends React.Component {
  constructor({ data, ...props }) {
    super(props)
    this.data = data
  }

  render() {
    return <LiveStreamBase data={this.data} twitchAccount={TWITCH_ACCOUNT} vid="https://stream.hardwarehookups.com.au/hls/test.m3u8"></LiveStreamBase>
  }
}

export default LiveStream

export const pageQuery = graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              title
              link
              stream
              location
              startTime
              endTime
              bannerImage
            }
            fields {
              date
            }
          }
        }
      }
    }
  `